/* FAQ Section */
.faq {
  padding: 60px 140px;
  background-color: #f9f9f9;
  margin: 0 auto;
}

/* FAQ Heading */
.faq__heading {
  font-size: 2rem;
  text-align: center;
  color: #4a4a4a;
  margin-bottom: 40px;
}

/* FAQ Item */
.faq__item {
  border-bottom: 1px solid #e0e0e0;
  padding: 20px 0;
}

/* FAQ Question */
.faq__question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

/* FAQ Number */
.faq__number {
  font-size: 1.5rem;
  color: #85b0ff;
  margin-right: 15px;
}

/* FAQ Title */
.faq__title {
  flex-grow: 1;
  font-size: 1.2rem;
  color: #555;
}

/* FAQ Toggle Icon */
.faq__toggle {
  font-size: 1.5rem;
  color: #555;
}

/* FAQ Answer */
.faq__answer {
  padding-top: 10px;
  font-size: 1rem;
  color: #777;
  line-height: 1.6;
  margin-left: 30px;
}

.faq__answer p {
  margin: 0;
}

/* Responsive Styles for Tablets and Smaller Screens (max-width: 768px) */
@media (max-width: 768px) {

  /* FAQ Section Padding */
  .faq {
    padding: 40px 20px;
  }

  /* FAQ Heading Font Size */
  .faq__heading {
    font-size: 1.5rem;
    /* Adjusted for smaller screens */
  }

  /* FAQ Question Layout */
  .faq__question {
    flex-direction: column;
    /* Stack number and title vertically */
    align-items: flex-start;
  }

  /* FAQ Number Size */
  .faq__number {
    font-size: 1.2rem;
    /* Smaller number font */
    margin-right: 0;
    margin-bottom: 10px;
    /* Space between number and title */
  }

  /* FAQ Title Font Size */
  .faq__title {
    font-size: 1.1rem;
    /* Slightly smaller font for title */
  }

  /* FAQ Toggle Icon */
  .faq__toggle {
    font-size: 1.3rem;
    /* Slightly smaller toggle icon */
  }

  /* FAQ Answer Font Size */
  .faq__answer {
    font-size: 0.9rem;
    /* Smaller font for answers */
    margin-left: 20px;
    /* Reduced margin for better alignment */
  }
}

/* Mobile View (max-width: 480px) */
@media (max-width: 480px) {

  /* FAQ Section Padding */
  .faq {
    padding: 30px 15px;
  }

  /* FAQ Heading Font Size */
  .faq__heading {
    font-size: 1.3rem;
    /* Adjusted for mobile */
  }

  /* FAQ Question Layout */
  .faq__question {
    flex-direction: column;
    /* Stack number and title vertically */
    align-items: flex-start;
  }

  /* FAQ Number Size */
  .faq__number {
    font-size: 1rem;
    /* Smaller font size for numbers */
    margin-right: 0;
    margin-bottom: 10px;
  }

  /* FAQ Title Font Size */
  .faq__title {
    font-size: 1rem;
    /* Smaller font size for titles */
  }

  /* FAQ Toggle Icon */
  .faq__toggle {
    font-size: 1.2rem;
    /* Smaller toggle icon */
  }

  /* FAQ Answer Font Size */
  .faq__answer {
    font-size: 0.85rem;
    /* Smaller answer font size for mobile */
    margin-left: 15px;
    /* Reduced margin for mobile */
  }
}