/* Testimonial Section */
.testimonial {
  padding: 60px;
  background-color: #b0d1f4;
  text-align: center;
}

/* Content Section */
.testimonial__content {
  margin-bottom: 40px;
}

/* Subheading */
.testimonial__subheading {
  font-size: 1rem;
  color: #fff;
  letter-spacing: 1.5px;
  margin-bottom: 10px;
}

/* Main Heading */
.testimonial__heading {
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 20px;
}

/* Description */
.testimonial__description {
  font-size: 1.1rem;
  color: #eaeaea;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto 30px;
}

/* Rating Section */
.testimonial__rating {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-bottom: 30px;
}

/* Star Icon */
.testimonial__star {
  font-size: 2rem;
  color: #4a4a4a;
}

/* Profiles Section */
.testimonial__profiles {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
  /* Allow wrapping of profiles on smaller screens */
  margin-top: 30px;
}

/* Profile Item */
.testimonial__profile {
  text-align: center;
  flex: 1 1 calc(33% - 20px);
  /* Adjust for 3 items per row */
  min-width: 250px;
  margin-bottom: 30px;
}

/* Profile Image */
.testimonial__profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

/* Profile Details */
.testimonial__profile-details {
  text-align: center;
}

/* Profile Name */
.testimonial__profile-name {
  font-size: 1.1rem;
  color: #fff;
}

/* Profile Role */
.testimonial__profile-role {
  font-size: 0.9rem;
  color: #d9e3f0;
}

/* Mobile View */
@media (max-width: 768px) {

  /* Heading Font Size */
  .testimonial__heading {
    font-size: 2rem;
  }

  /* Description Font Size */
  .testimonial__description {
    font-size: 1rem;
  }

  /* Profiles Section */
  .testimonial__profiles {
    gap: 30px;
  }

  /* Profile Item */
  .testimonial__profile {
    flex: 1 1 calc(50% - 20px);
    /* Two profiles per row on tablets */
  }

  /* Profile Name */
  .testimonial__profile-name {
    font-size: 1rem;
    /* Adjust name font size */
  }

  /* Profile Role */
  .testimonial__profile-role {
    font-size: 0.85rem;
    /* Adjust role font size */
  }
}

/* Extra-small Mobile View (max-width: 480px) */
@media (max-width: 480px) {

  /* Heading Font Size */
  .testimonial__heading {
    font-size: 1.5rem;
    /* Smaller font for small screens */
  }

  /* Description Font Size */
  .testimonial__description {
    font-size: 0.9rem;
    /* Even smaller font for mobile */
    max-width: 90%;
    /* Allow more space for description */
  }

  /* Rating Section */
  .testimonial__rating {
    gap: 3px;
    /* Reduce gap between stars on mobile */
  }

  /* Profile Item */
  .testimonial__profile {
    flex: 1 1 100%;
    /* Stack profile items on small screens */
    margin-bottom: 20px;
    /* Reduce margin for stacked items */
  }

  /* Profile Image */
  .testimonial__profile-image {
    width: 70px;
    /* Smaller image for mobile */
    height: 70px;
  }

  /* Profile Name */
  .testimonial__profile-name {
    font-size: 1rem;
    /* Adjust name size */
  }

  /* Profile Role */
  .testimonial__profile-role {
    font-size: 0.8rem;
    /* Smaller role size */
  }
}