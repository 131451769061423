/* About Us Section */
.about-us {
  padding: 60px 20px;
  max-width: 1200px;
  margin: auto;
}

/* Main title */
.about-us__main-title {
  text-align: center;
  font-size: 3rem;
  margin-bottom: -20px;
  color: #96C0EA;
}

/* Container for image and content */
.about-us__container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

/* Image container */
.about-us__image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Image styling */
.about-us__image {
  width: 100%;
  max-width: 500px;
  border-radius: 15px;
  object-fit: cover;
}

/* Content container */
.about-us__content {
  flex: 1.5;
  padding: 10px;
}

/* Content title */
.about-us__title {
  font-size: 36px;
  margin-bottom: 20px;
  color: #8D8BA7;
  text-align: justify;
}

/* Description styling */
.about-us__description {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 10px;
  color: #8D8BA7;
  text-align: justify;
}

/* Mission and Vision Section */
.about-us__mission-vision {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Individual mission and vision blocks */
.about-us__mission,
.about-us__vision {
  background: #f5f5f5;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Mobile and tablet view adjustments */
@media (max-width: 768px) {

  /* Main title */
  .about-us__main-title {
    font-size: 2rem;
  }

  /* Container for image and content */
  .about-us__container {
    flex-direction: column;
    /* Stack the image and content vertically */
    align-items: center;
  }

  /* Image container */
  .about-us__image-container {
    margin-bottom: 20px;
    /* Add space between image and content */
  }

  /* Content container */
  .about-us__content {
    flex: 1;
    /* Allow content to take full width */
    text-align: center;
    /* Center align content text */
  }

  /* Title */
  .about-us__title {
    font-size: 28px;
    text-align: center;
    /* Center the title on small screens */
  }

  /* Description text */
  .about-us__description {
    font-size: 16px;
  }

  /* Mission and Vision */
  .about-us__mission-vision {
    gap: 10px;
  }

  /* Mission and Vision blocks */
  .about-us__mission,
  .about-us__vision {
    padding: 15px;
    font-size: 16px;
  }
}

/* Extra-small screens (e.g., mobile phones in portrait) */
@media (max-width: 480px) {

  /* Main title */
  .about-us__main-title {
    font-size: 1.8rem;
  }

  /* Content title */
  .about-us__title {
    font-size: 24px;
  }

  /* Description text */
  .about-us__description {
    font-size: 14px;
  }

  /* Mission and Vision blocks */
  .about-us__mission,
  .about-us__vision {
    font-size: 14px;
    padding: 10px;
  }
}