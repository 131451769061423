/* Hero section container */
.hero-section {
  background: url("../Images/baanner.svg") no-repeat center center;
  background-size: cover;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 0 20px;
  box-sizing: border-box;
}

/* Content wrapper */
.hero-section__content {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Title */
.hero-section__title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 20px;
}

/* Subtitle */
.hero-section__subtitle {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

/* Buttons wrapper */
.hero-section__buttons {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

/* Buttons */
.hero-section__button {
  padding: 15px 30px;
  font-size: 1rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Primary button */
.hero-section__button--primary {
  background-color: #96C0EA;
  color: white;
  font-weight: 600;
}

.hero-section__button--primary:hover {
  background-color: #86b0d8;
}

/* Secondary button */
.hero-section__button--secondary {
  background-color: transparent;
  color: white;
  font-weight: 600;
  border: 2px solid white;
}

.hero-section__button--secondary:hover {
  background-color: white;
  color: #0077cc;
}

/* Media Queries for responsiveness */

/* Tablet view */
@media (max-width: 768px) {
  .hero-section {
    height: 80vh;
    padding: 0 10px;
  }

  .hero-section__title {
    font-size: 2.5rem;
  }

  .hero-section__subtitle {
    font-size: 1rem;
    margin-bottom: 30px;
  }

  .hero-section__buttons {
    gap: 15px;
  }

  .hero-section__button {
    padding: 12px 25px;
    font-size: 0.9rem;
  }
}

/* Mobile view */
@media (max-width: 480px) {
  .hero-section {
    height: auto;
    padding: 40px 10px;
    flex-direction: column;
  }

  .hero-section__title {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .hero-section__subtitle {
    font-size: 0.9rem;
    margin-bottom: 20px;
  }

  .hero-section__buttons {
    flex-direction: column;
    gap: 15px;
  }

  .hero-section__button {
    padding: 10px 20px;
    font-size: 0.8rem;
    width: 100%;
  }
}