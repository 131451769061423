/* Solutions Section */
.solutions {
  padding: 50px;
  background-color: #f9f9f9;
  text-align: center;
}

/* Main Heading */
.main {
  font-size: 25px;
  color: #8D8BA7;
  margin-bottom: 20px;
  margin-bottom: -10px;
}

/* Title */
.solutions__title {
  font-size: 2rem;
  color: #96C0EA;
  margin-bottom: 20px;
}

/* Subtitle */
.solutions__subtitle {
  font-size: 1.2rem;
  /* Adjusted size for better readability */
  color: #9795B5;
  margin-bottom: 50px;
  max-width: 700px;
  margin: 0 auto;
}

/* Solutions Container */
.solutions__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  margin-top: 40px;
}

/* Individual Solution Item */
.solution-item {
  flex: 1 1 calc(30% - 20px);
  /* Adjust width of each item */
  min-width: 280px;
  max-width: 300px;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;
}

/* Hover Effect */
.solution-item:hover {
  transform: translateY(-10px);
}

/* Solution Item Image */
.solution-item img {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  border-radius: 50%;
  object-fit: cover;
}

/* Solution Item Heading */
.solution-item h3 {
  font-size: 1.2rem;
  color: #5D5A88;
  margin-bottom: 10px;
}

/* Solution Item Paragraph */
.solution-item p {
  font-size: 1rem;
  color: #9795B5;
  line-height: 1.5;
}

/* Mobile View */
@media (max-width: 768px) {

  /* Adjust Title Font Size */
  .solutions__title {
    font-size: 1.5rem;
  }

  /* Adjust Subtitle Font Size */
  .solutions__subtitle {
    font-size: 1rem;
    max-width: 600px;
    /* Ensure subtitle fits well on mobile */
  }

  /* Solutions Container */
  .solutions__container {
    gap: 30px;
  }

  /* Solution Item */
  .solution-item {
    flex: 1 1 calc(50% - 20px);
    /* Adjust for 2 items per row */
  }

  /* Solution Item Heading */
  .solution-item h3 {
    font-size: 1.1rem;
    /* Smaller heading for mobile */
  }

  /* Solution Item Paragraph */
  .solution-item p {
    font-size: 0.9rem;
    /* Smaller paragraph text */
  }
}

/* Extra-small Mobile View (max-width: 480px) */
@media (max-width: 480px) {

  /* Adjust Title Font Size */
  .solutions__title {
    font-size: 1.3rem;
    /* Even smaller font for very small screens */
  }

  /* Adjust Subtitle Font Size */
  .solutions__subtitle {
    font-size: 0.9rem;
    max-width: 500px;
    /* Further adjust the subtitle width */
  }

  /* Solutions Container */
  .solutions__container {
    gap: 20px;
    /* Decrease gap between items */
  }

  /* Solution Item */
  .solution-item {
    flex: 1 1 100%;
    /* Stack the items in a single column */
    margin-bottom: 20px;
  }

  /* Solution Item Heading */
  .solution-item h3 {
    font-size: 1rem;
    /* Even smaller heading for very small screens */
  }

  /* Solution Item Paragraph */
  .solution-item p {
    font-size: 0.8rem;
    /* Even smaller paragraph text */
  }

  /* Solution Item Image */
  .solution-item img {
    width: 80px;
    /* Adjust image size */
    height: 80px;
  }
}