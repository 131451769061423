/* Footer Section */
.footer {
  background-color: white;
  padding: 40px 0;
  color: #555;
}

/* Footer Container */
.footer__container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
}

/* Footer Section */
.footer__section {
  flex: 1;
  min-width: 150px;
}

/* Footer Logo */
.footer__logo {
  margin-bottom: 20px;
  margin-top: 20px;
}

/* Footer Description */
.footer__description {
  font-size: 1rem;
  margin-bottom: 20px;
  text-align: justify;
}

/* Social Icons */
.footer__social-icons {
  display: flex;
  gap: 10px;
  margin-left: 40px;
}

/* Social Link Style */
.footer__social-link {
  color: #555;
  font-size: 1.2rem;
  display: inline-block;
  padding: 10px;
  border-radius: 50%;
  background-color: #e0e0e0;
}

/* Footer Title */
.footer__title {
  font-size: 1.1rem;
  margin-bottom: 15px;
  color: #4a4a4a;
}

/* Footer List Style */
.footer__list {
  list-style: none;
  padding: 0;
}

/* Footer List Item */
.footer__list-item {
  margin-bottom: 10px;
  font-size: 0.9rem;
}

.footer__list-item a {
  text-decoration: none;
  color: inherit;
}

/* Footer Bottom Section */
.footer__bottom {
  text-align: center;
  margin-top: 40px;
  font-size: 0.9rem;
  color: #777;
}

.footer__bottom a {
  color: inherit;
  text-decoration: none;
  margin: 0 5px;
}

/* Media Query for Tablet and Smaller Devices (max-width: 768px) */
@media (max-width: 768px) {

  /* Adjust Footer Container */
  .footer__container {
    flex-direction: column;
    text-align: center;
  }

  /* Adjust Footer Section for better spacing */
  .footer__section {
    margin-bottom: 20px;
  }

  /* Adjust Footer Social Icons */
  .footer__social-icons {
    justify-content: center;
    margin-left: 0;
  }

  /* Footer Logo Adjustments */
  .footer__logo {
    margin-bottom: 30px;
    text-align: center;
  }

  /* Footer Description Adjustment */
  .footer__description {
    text-align: center;
  }

  /* Footer List Adjustments */
  .footer__list-item {
    font-size: 1rem;
    /* Larger text for better readability on mobile */
  }
}

/* Mobile View Adjustments (max-width: 480px) */
@media (max-width: 480px) {

  /* Adjust Footer Title Size */
  .footer__title {
    font-size: 1rem;
    /* Smaller title font for mobile */
  }

  /* Adjust Footer Description Font Size */
  .footer__description {
    font-size: 0.9rem;
  }

  /* Footer Social Icons Adjustments */
  .footer__social-link {
    font-size: 1rem;
    /* Adjust social icon size for mobile */
    padding: 8px;
  }

  /* Footer Bottom Font Size */
  .footer__bottom {
    font-size: 0.8rem;
    margin-top: 30px;
  }
}