/* CallToAction Section */
.cta {
  background-color: #b0d1f4;
  padding: 50px 0;
}

/* CTA Container */
.cta__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
}

/* CTA Content */
.cta__content {
  flex: 1;
  padding-right: 40px;
}

/* CTA Heading */
.cta__heading {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 20px;
}

/* CTA Buttons */
.cta__buttons {
  display: flex;
  gap: 20px;
}

.cta__button {
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}

.cta__button--primary {
  background-color: #fff;
  color: #4a4a4a;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.cta__button--secondary {
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
}

/* CTA Image */
.cta__image {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.cta__image-content {
  border-radius: 10px;
  max-width: 100%;
  height: auto;
}

/* Responsive Styles for Tablets and Smaller Screens (max-width: 768px) */
@media (max-width: 768px) {

  /* Adjust CTA Container for Mobile View */
  .cta__container {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }

  /* Adjust CTA Content */
  .cta__content {
    padding-right: 0;
    margin-bottom: 20px;
    /* Space between content and image */
  }

  /* Center the Image */
  .cta__image {
    justify-content: center;
    margin-bottom: 20px;
    /* Space below image */
  }

  /* Adjust CTA Heading Font Size */
  .cta__heading {
    font-size: 1.6rem;
    /* Smaller heading on mobile */
    margin-bottom: 15px;
  }

  /* Adjust Button Font Size */
  .cta__button {
    font-size: 0.9rem;
    /* Smaller buttons on mobile */
    padding: 8px 16px;
    /* Adjust padding for smaller screens */
  }

  /* Adjust Button Gap */
  .cta__buttons {
    gap: 15px;
    /* Reduced gap for smaller screens */
  }
}

/* Mobile View (max-width: 480px) */
@media (max-width: 480px) {

  /* CTA Heading Font Size */
  .cta__heading {
    font-size: 1.4rem;
    /* Further reduce font size for mobile */
    margin-bottom: 10px;
    text-align: justify;
  }

  /* Adjust Button Font Size */
  .cta__button {
    font-size: 0.85rem;
    /* Further reduced button size */
    padding: 8px 15px;
  }

  /* Adjust Button Gap */
  .cta__buttons {
    gap: 10px;
    /* Reduce gap between buttons */
  }

  /* Adjust CTA Image Size */
  .cta__image-content {
    max-width: 80%;
    /* Adjust image size for mobile */
    margin: 0 auto;
    /* Center image */
  }

  /* Remove extra space around CTA content */
  .cta__content {
    margin-bottom: 15px;
    /* Reduced margin for mobile */
  }
}