/* Navbar Container */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  background-color: white;
  position: relative;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.navbar__logo-image {
  width: 50px;
  height: auto;
}

/* Navbar Menu */
.navbar__menu {
  display: flex;
  align-items: center;
  gap: 40px;
}

.navbar__menu-list {
  display: flex;
  list-style: none;
  gap: 30px;
  margin: 0;
  padding: 0;
}

.navbar__menu-item {
  cursor: pointer;
  position: relative;
  color: #3e3e3e;
}

.navbar__menu-item:hover {
  color: #007bff;
}

/* Submenu */
.navbar__submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 10px;
}

.navbar__menu-item:hover .navbar__submenu {
  display: block;
}

.navbar__submenu-item {
  padding: 5px 10px;
  cursor: pointer;
  white-space: nowrap;
}

.navbar__submenu-item:hover {
  background-color: #f0f0f0;
}

/* Auth Section */
.navbar__auth {
  display: flex;
  align-items: center;
  gap: 15px;
}

.navbar__auth-login {
  color: #007bff;
  text-decoration: none;
}

.navbar__auth-signup {
  background-color: #5dade2;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
}

.navbar__auth-signup:hover {
  background-color: #007bff;
}

/* Hamburger Menu Icon */
.navbar__toggle {
  display: none;
  font-size: 1.8rem;
  background: none;
  border: none;
  cursor: pointer;
}

/* Mobile Menu Styles */
@media (max-width: 768px) {
  .navbar {
    padding: 10px 20px;
  }

  .navbar__menu {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 100%;
    right: -36px;
    width: 100%;
    background-color: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: justify;
  }

  .navbar__menu.active {
    display: flex;
  }

  .navbar__menu-list {
    flex-direction: column;
    gap: 15px;
  }

  .navbar__auth {
    /* flex-direction: column;
    gap: 10px; */
    display: none;
  }

  .navbar__toggle {
    display: block;
  }
}