/* Our Products Section */
.products {
  padding: 40px;
  background-color: #f9f9f9;
  text-align: center;
}

/* Title */
.products__title {
  font-size: 2rem;
  color: #4a4a4a;
  margin-bottom: 30px;
}

/* Container for product cards */
.products__container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  /* Allows wrapping of cards on smaller screens */
}

/* Individual Product Card */
.product-card {
  flex: 1 1 300px;
  /* Allow cards to grow and shrink with a minimum width of 300px */
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  text-align: left;
  position: relative;
}

/* Highlighted Product Card */
.product-card--highlight {
  background-color: #eef5ff;
}

/* Image */
.product-card__image {
  display: block;
  margin: 0 auto 20px auto;
  width: 100px;
}

/* Subtitle */
.product-card__subtitle {
  font-size: 1rem;
  text-align: center;
  color: #888;
  margin-bottom: 10px;
}

/* Product Name */
.product-card__name {
  font-size: 1.8rem;
  text-align: center;
  color: #3b73ff;
  margin-bottom: 20px;
}

/* Product Description */
.product-card__description {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  margin-bottom: 20px;
}

/* Button */
.product-card__button {
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
  background-color: #3b73ff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.product-card__button:hover {
  background-color: #2a5ed8;
}

/* Image Overlay */
.product-card__image-overlay {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

/* Overlay Title */
.product-card__overlay-title {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 1.5rem;
  color: white;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}

/* Mobile and Tablet View */
@media (max-width: 768px) {

  /* Product Container */
  .products__container {
    justify-content: center;
    /* Center align cards */
  }

  /* Product Card */
  .product-card {
    flex: 1 1 100%;
    /* Stack the cards and allow them to take full width */
    margin-bottom: 20px;
    /* Space between cards */
  }

  /* Product Name */
  .product-card__name {
    font-size: 1.5rem;
    /* Reduce font size for mobile */
  }

  /* Description */
  .product-card__description {
    font-size: 0.9rem;
    /* Reduce description font size */
  }

  /* Button */
  .product-card__button {
    padding: 8px 18px;
    /* Smaller padding for mobile */
    font-size: 0.9rem;
    /* Smaller font size */
  }
}

/* Extra-small Screens (Mobile in Portrait) */
@media (max-width: 480px) {

  /* Title */
  .products__title {
    font-size: 1.5rem;
    /* Reduce title font size */
  }

  /* Product Name */
  .product-card__name {
    font-size: 1.2rem;
    /* Further reduce product name font size */
  }

  /* Description */
  .product-card__description {
    font-size: 0.85rem;
    /* Further reduce description font size */
  }

  /* Button */
  .product-card__button {
    padding: 6px 12px;
    /* Smaller button size */
    font-size: 0.85rem;
    /* Smaller font size */
  }

  /* Image */
  .product-card__image {
    width: 80px;
    /* Smaller image size */
  }
}