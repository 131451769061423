/* Container for the stats section */
.stats-section {
  text-align: center;
  padding: 60px 20px;
}

/* Main heading */
.stats-section__heading {
  font-size: 2rem;
  color: #6c8dc8;
  margin-bottom: 10px;
}

/* Subheading */
.stats-section__subheading {
  font-size: 1rem;
  color: #b0b0b0;
  margin-bottom: 40px;
}

/* Cards container */
.stats-section__cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  /* Allow cards to wrap on smaller screens */
}

/* Individual card */
.stats-section__card {
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  width: 200px;
  text-align: center;
  box-sizing: border-box;
  /* Ensure padding does not affect width */
}

/* Icon */
.stats-section__icon {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #6c8dc8;
}

/* Title */
.stats-section__title {
  font-size: 1.2rem;
  color: #7d7d7d;
  margin-bottom: 15px;
}

/* Value */
.stats-section__value {
  font-size: 2rem;
  color: #6c8dc8;
  font-weight: bold;
}

/* Mobile view adjustments */
@media (max-width: 768px) {

  /* Main heading */
  .stats-section__heading {
    font-size: 1.5rem;
  }

  /* Subheading */
  .stats-section__subheading {
    font-size: 0.9rem;
  }

  /* Cards container */
  .stats-section__cards {
    flex-direction: column;
    /* Stack the cards vertically */
    align-items: center;
    /* Center the cards */
  }

  /* Individual card */
  .stats-section__card {
    width: 100%;
    /* Make cards take full width on smaller screens */
    max-width: 300px;
    /* Limit max width of cards */
    margin-bottom: 20px;
    /* Add some space between stacked cards */
  }

  /* Icon */
  .stats-section__icon {
    font-size: 1.8rem;
  }

  /* Title */
  .stats-section__title {
    font-size: 1rem;
  }

  /* Value */
  .stats-section__value {
    font-size: 1.8rem;
  }
}