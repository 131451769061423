/* Why Choose Us Section */
.why-choose-us {
  padding: 60px 40px;
  background-color: #f9f9f9;
  text-align: center;
}

/* Subheading */
.why-choose-us__subheading {
  font-size: 1rem;
  letter-spacing: 1.5px;
  color: #8D8BA7;
  margin-bottom: 10px;
}

/* Main Heading */
.why-choose-us__heading {
  font-size: 2rem;
  color: #96C0EA;
  margin-bottom: 20px;
}

/* Description */
.why-choose-us__description {
  font-size: 19px;
  color: #9795B5;
  margin-bottom: 50px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Features Container */
.why-choose-us__features {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;
  /* Ensure that cards wrap on smaller screens */
  margin-top: 30px;
}

/* Individual Feature Card */
.feature-card {
  border-radius: 10px;
  padding: 20px;
  width: 30%;
  text-align: center;
  border: 1px solid #D4D2E3;
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  /* Slight hover effect */
}

/* Feature Card Number */
.feature-card__number {
  font-size: 56px;
  color: #96C0EA;
  margin-bottom: 10px;
}

/* Feature Card Title */
.feature-card__title {
  font-size: 1.3rem;
  color: #5D5A88;
  margin-bottom: 15px;
}

/* Feature Card Description */
.feature-card__description {
  font-size: 1rem;
  color: #9795B5;
  line-height: 1.6;
}

/* Responsive for Tablets and Smaller Screens (max-width: 768px) */
@media (max-width: 768px) {
  .why-choose-us__features {
    flex-direction: column;
    /* Stack feature cards vertically */
    justify-content: center;
    gap: 20px;
  }

  .feature-card {
    width: 80%;
    /* Make cards wider on tablets */
    margin-left: auto;
    margin-right: auto;
  }

  /* Adjust Heading Font Size */
  .why-choose-us__heading {
    font-size: 1.8rem;
  }

  /* Adjust Description Font Size */
  .why-choose-us__description {
    font-size: 1.1rem;
    max-width: 90%;
    /* Allow more space for the description */
  }

  /* Adjust the Subheading Font Size */
  .why-choose-us__subheading {
    font-size: 0.9rem;
  }
}

/* Mobile View (max-width: 480px) */
@media (max-width: 480px) {

  /* Adjust Heading Font Size */
  .why-choose-us__heading {
    font-size: 1.5rem;
    /* Smaller font for mobile */
  }

  /* Adjust Description Font Size */
  .why-choose-us__description {
    font-size: 0.9rem;
    /* Smaller font for mobile */
    max-width: 90%;
    /* Ensure description fits on mobile */
  }

  /* Feature Card Adjustments */
  .feature-card {
    width: 90%;
    /* Make cards take almost full width */
    margin-left: auto;
    margin-right: auto;
  }

  .feature-card__number {
    font-size: 48px;
    /* Smaller number size for mobile */
  }

  .feature-card__title {
    font-size: 1.1rem;
    /* Smaller title font size */
  }

  .feature-card__description {
    font-size: 0.9rem;
    /* Smaller description font size */
  }
}